<!--eslint-disable vue/no-mutating-props-->
<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <b-col cols="12">
          <h6 class="filter-heading d-none d-lg-block">
            Filters
            <span class="ml-4" />
          </h6>
        </b-col>

        <!-- Filters' Card -->
        <b-card no-body>

          <!-- Price Slider -->
          <ais-range-input attribute="price">
            <div
              slot-scope="{ currentRefinement, range, refine }"
              class="price-slider"
            >
              <h6 class="filter-title ml-1 mt-2 mb-4">
                Price
                <!-- <br>toValue: {{ toValue(currentRefinement, range) }}
                <br>defaultRange: {{ defaultMin }} - {{ defaultMax }}
                <br>setRange: {{ setMin }} - {{ setMax }}
                <br>calcMinRange {{ Math.min(range.min, currentRefinement.min || range.min) }}
                <br>calcMaxRange {{ Math.max(range.max, currentRefinement.max || range.max) }}
               <br>{{ defaultMin }}
                <br>{{ defaultMax }}  -->
              </h6>
              <vue-slider
                class="ml-2 mr-2"
                tooltip="always"
                :tooltip-placement="['top', 'bottom']"
                :tooltip-formatter="priceTooltipFormatter"
                :value="toValueForPrice(currentRefinement, range)"
                :min="defaultMinForPrice || range.min"
                :max="defaultMaxForPrice || range.max"
                :lazy="true"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @change="customRefineForPrice({ min:$event[0], max:$event[1]}, range, refine)"
              />
            </div>

          </ais-range-input>
          <!-- The attribute passed to the attribute prop must be added in attributes for faceting,
          either on the dashboard or using attributesForFaceting with the API. The values inside attribute must be numbers, not strings. -->

          <app-collapse>

            <app-collapse-item
              title="Measurements"
              class="mt-4"
            >
              <b-col>
                <!-- Frotnage Slider -->
                <ais-range-input attribute="frontage">
                  <div
                    slot-scope="{ currentRefinement, range, refine }"
                    class="price-slider"
                  >
                    <h6 class="mb-4 mt-1">
                      Frontage
                      <!-- <br>currentRefinement: {{ currentRefinement }}
                    <br>range: {{ range }} -->
                    </h6>
                    <vue-slider
                      tooltip="always"
                      class="mb-5"
                      :tooltip-placement="['top', 'bottom']"
                      :tooltip-formatter="addingMetersTooltipFormatter"
                      :value="toValueForFrontage(currentRefinement, range)"
                      :min="defaultMinForFrontage || range.min"
                      :max="defaultMaxForFrontage || range.max"
                      :lazy="true"
                      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @change="customRefineForFrontage({ min:$event[0], max:$event[1]}, range, refine)"
                    />
                  </div>
                </ais-range-input>

                <!-- Depth Slider -->
                <ais-range-input attribute="depth">
                  <div
                    slot-scope="{ currentRefinement, range, refine }"
                    class="price-slider"
                  >
                    <h6 class="filter-title mb-4">
                      Depth
                      <!-- <br>currentRefinement: {{ currentRefinement }}
                    <br>range: {{ range }} -->
                    </h6>
                    <vue-slider
                      class="mb-5"
                      :tooltip-placement="['top', 'bottom']"
                      tooltip="always"
                      :tooltip-formatter="addingMetersTooltipFormatter"
                      :value="toValueForDepth(currentRefinement, range)"
                      :min="defaultMinForDepth || range.min"
                      :max="defaultMaxForDepth || range.max"
                      :lazy="true"
                      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @change="customRefineForDepth({ min:$event[0], max:$event[1]}, range, refine)"
                    />
                  </div>
                </ais-range-input>

                <!-- Size Slider -->
                <ais-range-input attribute="size">
                  <div
                    slot-scope="{ currentRefinement, range, refine }"
                    class="price-slider"
                  >
                    <h6 class="filter-title mb-4">
                      Size
                      <!-- <br>currentRefinement: {{ currentRefinement }}
                    <br>range: {{ range }} -->
                    </h6>
                    <vue-slider
                      tooltip="always"
                      class="mb-1"
                      :tooltip-placement="['top', 'bottom']"
                      :tooltip-formatter="addingMetersTooltipFormatter"
                      :value="toValueForSize(currentRefinement, range)"
                      :min="defaultMinForSize || range.min"
                      :max="defaultMaxForSize || range.max"
                      :lazy="true"

                      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @change="customRefineForSize({ min:$event[0], max:$event[1]}, range, refine)"
                    />
                  </div>
                </ais-range-input>
              </b-col>
            </app-collapse-item>
          </app-collapse>

          <!-- Projects -->
          <ais-refinement-list
            attribute="project.name"
            searchable
          >
            <div
              slot-scope="{ items, refine, searchForItems}"
            >
              <div class="brands m-1 pt-1">

                <h6 class="mb-1">
                  Projects
                </h6>
                <b-form-input
                  :v-model="searchQuery"
                  class="mb-1 "
                  type="search"

                  placeholder="Search..."
                  @input="searchForItems($event)"
                />
                <!-- {{ log("Projects In Checkbox: ") }}
                {{ log(projectsSelected) }} -->
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="projectsSelected"
                    :aria-describedby="ariaDescribedby"
                  >
                    <!-- {{ log(selected) }} -->
                    <!--eslint-disable-next-line vue/require-v-for-key-->
                    <div v-for="item in items.map(i => i)">
                      <b-form-checkbox
                        :value="item.label"
                        class="mb-1"
                        @change="refine(item.value);selectedProjectRefinements(item)"
                      >
                        <b-row>
                          <b-col class="col">
                            {{ item.label }}

                          </b-col>

                          <b-col class="col-auto">
                            <b-badge variant="light-secondary">
                              {{ item.count }}
                            </b-badge>
                          </b-col>
                        </b-row>

                      </b-form-checkbox>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>

              </div>
            </div>
          </ais-refinement-list>

        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BFormGroup, BBadge, BFormCheckbox, VBToggle, BFormCheckboxGroup,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { mapActions } from 'vuex'

export default {

  directives: {
    'b-toggle': VBToggle,
    ripple: Ripple,
  },
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    AppCollapse,
    AppCollapseItem,

    BCard,
    BFormInput,

    // BFormRadio,
    BFormGroup,
    BBadge,
    BFormCheckbox,

    // 3rd Party
    VueSlider,

  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {

      // Defaults are used ensure the orginal max/mins of result set is maintained
      defaultMinForPrice: null,
      defaultMaxForPrice: null,
      defaultMinForFrontage: null,
      defaultMaxForFrontage: null,
      defaultMinForDepth: null,
      defaultMaxForDepth: null,
      defaultMinForSize: null,
      defaultMaxForSize: null,
      checked: false,

      // There is no way to persist the chosen refinement so storing it in state
      setMinForPrice: null,
      setMaxForPrice: null,
      setMinForFrontage: null,
      setMaxForFrontage: null,
      setMinForDepth: null,
      setMaxForDepth: null,
      setMinForSize: null,
      setMaxForSize: null,
      searchQuery: '',

      priceTooltipFormatter: v => `$${v / 1000}k`,
      addingMetersTooltipFormatter: v => `${v}m`,
    }
  },
  computed: {
    // since we using modules you need to specfiy the modules.
    /* Need to use to get the value of projects selected, like this because when you give a v-modal a value that uses a setter in vuex
      that's why i was getting the error "computed property "projectsSelected" was assigned to but it has no setter". https://stackoverflow.com/questions/47460765/vuex-vue-warn-computed-property-username-was-assigned-to-but-it-has-no-set
      */
    projectsSelected: {
      get() {
        return this.$store.state.search.projectsSelected
      },
      set() {
        return null
      },

    },
  },
  created() {
    //  This is here to clear the project checkbox list because any clicked projects will be stored in state and the state will not be delte untill refresh the project
    //  and i have not figured out a way to refine algolia search on page load.
    this.emptyProjectsSelected()
    this.removeSearchRefinement()
  },
  methods: {
    ...mapActions('search', [
      'updateProjectsSelected',
      'emptyProjectsSelected',
      'removeSearchRefinement',
    ]),
    selectedProjectRefinements(item) {
      this.updateProjectsSelected(item.value)
    },
    // log(value) {
    //   console.log(value)
    // },
    checkboxCustomRefine(event, item, refine) {
      refine(item.value)
      // if (typeof event === 'boolean') {
      //   refine(item.value)
      // } else { refine(event) }
    },
    customRefineForPrice(event, range, refine) {
      // Set the chosen amounts as per slider (regardless if only on threshold is selected)
      this.setMinForPrice = event.min
      this.setMaxForPrice = event.max

      // Provides Algolia SDK with the event value if it is within the range of the result set
      // or the range value if it is not.
      const normalizedEvent = {
        min: event.min < range.min ? range.min : event.min,
        max: event.max > range.max ? range.max : event.max,
      }

      // console.log(`Event: ${JSON.stringify(event)} Normalised: ${JSON.stringify(normalizedEvent)}`)
      refine(normalizedEvent)
    },
    customRefineForFrontage(event, range, refine) {
      this.setMinForFrontage = event.min
      this.setMaxForFrontage = event.max

      const normalizedEvent = {
        min: event.min < range.min ? range.min : event.min,
        max: event.max > range.max ? range.max : event.max,
      }

      refine(normalizedEvent)
    },
    customRefineForDepth(event, range, refine) {
      this.setMinForDepth = event.min
      this.setMaxForDepth = event.max

      const normalizedEvent = {
        min: event.min < range.min ? range.min : event.min,
        max: event.max > range.max ? range.max : event.max,
      }

      // console.log(`Event: ${JSON.stringify(event)} Normalised: ${JSON.stringify(normalizedEvent)}`)
      refine(normalizedEvent)
    },
    customRefineForSize(event, range, refine) {
      // Set the chosen amounts as per slider (regardless if only on threshold is selected)
      this.setMinForSize = event.min
      this.setMaxForSize = event.max

      // Provides Algolia SDK with the event value if it is within the range of the result set
      // or the range value if it is not.
      const normalizedEvent = {
        min: event.min < range.min ? range.min : event.min,
        max: event.max > range.max ? range.max : event.max,
      }

      // console.log(`Event: ${JSON.stringify(event)} Normalised: ${JSON.stringify(normalizedEvent)}`)
      refine(normalizedEvent)
    },
    toValueForPrice(currentRefinement, range) {
      // console.log(`toValue - currentRefine: ${JSON.stringify(currentRefinement)} - Range: ${JSON.stringify(range)}`)
      // console.log(`SetMin: ${this.setMin} - SetMax: ${this.setMax}`)
      // Set the defaults upon component load
      if (!this.defaultMinForPrice) {
        this.defaultMinForPrice = range.min
      }

      if (!this.defaultMaxForPrice) {
        this.defaultMaxForPrice = range.max
      }

      // Return the set values if a selection has been previously made on slider
      // if not use the defaults

      let min = this.defaultMinForPrice
      let max = this.defaultMaxForPrice

      if (!currentRefinement.min) {
        min = range.min
      } else {
        min = this.setMinForPrice ? this.setMinForPrice : this.defaultMinForPrice
      }

      if (!currentRefinement.max) {
        max = range.max
      } else {
        max = this.setMaxForPrice ? this.setMaxForPrice : this.defaultMaxForPrice
      }
      const value = [min, max]
      // console.log(`toValue - ${value}`)
      return value
    },
    toValueForFrontage(currentRefinement, range) {
      if (!this.defaultMinForFrontage) {
        this.defaultMinForFrontage = range.min
      }

      if (!this.defaultMaxForFrontage) {
        this.defaultMaxForFrontage = range.max
      }

      let min = this.defaultMinForFrontage
      let max = this.defaultMaxForFrontage

      if (!currentRefinement.min) {
        min = range.min
      } else {
        min = this.setMinForFrontage ? this.setMinForFrontage : this.defaultMinForFrontage
      }

      if (!currentRefinement.max) {
        max = range.max
      } else {
        max = this.setMaxForFrontage ? this.setMaxForFrontage : this.defaultMaxForFrontage
      }
      const value = [min, max]
      return value
    },
    toValueForDepth(currentRefinement, range) {
      if (!this.defaultMinForDepth) {
        this.defaultMinForDepth = range.min
      }

      if (!this.defaultMaxForDepth) {
        this.defaultMaxForDepth = range.max
      }

      let min = this.defaultMinForDepth
      let max = this.defaultMaxForDepth

      if (!currentRefinement.min) {
        min = range.min
      } else {
        min = this.setMinForDepth ? this.setMinForDepth : this.defaultMinForDepth
      }

      if (!currentRefinement.max) {
        max = range.max
      } else {
        max = this.setMaxForDepth ? this.setMaxForDepth : this.defaultMaxForDepth
      }
      const value = [min, max]
      return value
    },
    toValueForSize(currentRefinement, range) {
      if (!this.defaultMinForSize) {
        this.defaultMinForSize = range.min
      }

      if (!this.defaultMaxForSize) {
        this.defaultMaxForSize = range.max
      }

      let min = this.defaultMinForSize
      let max = this.defaultMaxForSize

      if (!currentRefinement.min) {
        min = range.min
      } else {
        min = this.setMinForSize ? this.setMinForSize : this.defaultMinForSize
      }

      if (!currentRefinement.max) {
        max = range.max
      } else {
        max = this.setMaxForSize ? this.setMaxForSize : this.defaultMaxForSize
      }
      const value = [min, max]
      return value
    },
  },

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }

}
</style>
